import React, { useEffect, useRef, useState } from 'react';
import OpcionBuscador from './opcionBuscador';
import styles from './Buscador.module.scss';
import { ImgBuscadorMobile } from '../../../imagenes/imagen-buscador-mobile';
import dynamic from 'next/dynamic';
import { SearchResultRow } from './searchResultRow';
import { SearchInput } from './searchInput';
import { useLocalStorage } from 'modules/hooks';
import { replaceAccents } from 'modules';
import { SearchAlert } from './searchAlert';
const Autocomplete = dynamic(() => import('react-autocomplete'));
const LOCAL_STORAGE_SEARCH = 'toctocSearchAddress';
export const Buscador = ({ usuario, tipoBusqueda, searchCallback, searchByCodeCallback, urlBase, isMobile, sessionId, defaultOperationIndex }) => {
	const OpcionBusqueda = {
		buscadorUbicacion: 1,
		buscardorCodigo: 2,
	};

	const getSearchText = (searchResult, isDisplay = false) => {
		if (searchResult === null || searchResult === undefined)
			return null;

		const text = !isDisplay ? replaceAccents(searchResult.label) : `${searchResult.label}, ${searchResult.sublabel.trim()}`;
		return text;
	};
	const { name, id: idUser, email } = usuario ?? {};
	const [tipoBuscador, setTipoBuscador] = useState(OpcionBusqueda.buscadorUbicacion);
	const [searchResult, setSearchResult] = useState([]);
	const [selectedValue, setSelectedValue] = useState(null);
	const [propertyType, setPropertyType] = useState(null);
	const [operationType, setOperationType] = useState(null);
	const [lastSearchResult, setLastSearchResult] = useLocalStorage(LOCAL_STORAGE_SEARCH);
	const [initSearchValue, setInitSearchValue] = useState(selectedValue ? getSearchText(selectedValue, true) : '');
	const [searchByCodeValue, setSearchByCodeValue] = useState('');
	const [searchByCodeStatus, setSearchByCodeStatus] = useState(null);
	const activeSearchType = useRef(tipoBuscador);
	const setupInitialState = () => {
		setSearchByCodeStatus(null);
	};
	const observerTipoBuscadorChange = () => {
		if (activeSearchType.current === tipoBuscador)
			return;
		activeSearchType.current = tipoBuscador;
		setSearchByCodeStatus(null);
		const selectedValueText = getSearchText(selectedValue, true) ?? '';
		if (initSearchValue !== selectedValueText)
			setInitSearchValue();
	};
	const setupInitSearchValue = () => {
		if (lastSearchResult === null || lastSearchResult === undefined)
			return;
		if (selectedValue === lastSearchResult)
			return;
		setSelectedValue(lastSearchResult);
		if (lastSearchResult !== null) {
			const displayText = getSearchText(lastSearchResult, true);
			if (displayText === initSearchValue)
				return;
			setInitSearchValue(displayText);
		}
	};

	useEffect(setupInitialState, []);
	useEffect(observerTipoBuscadorChange, [tipoBuscador, initSearchValue, selectedValue]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(setupInitSearchValue, [initSearchValue, lastSearchResult, setSelectedValue]);
	useEffect(() => {
		if (searchByCodeStatus === null || searchByCodeStatus === undefined ||
			searchByCodeStatus.status !== 'success' ||
			searchByCodeStatus.url === null ||
			searchByCodeStatus.url === undefined)
			return;

		window.location = searchByCodeStatus.url;
	}, [searchByCodeStatus]);
	const inputElement = (props) => {
		const { value, isloading, didtrysearch } = props;
		const isNotLoading = isloading === 'false';
		const didTrySearchYet = didtrysearch === 'true';
		const displayText = getSearchText(selectedValue, true);
		const valueContainsSelected = value.includes(displayText);
		const selectedContainsValue = displayText !== null && displayText.includes(value);
		const noResultAvailable = searchResult.length === 0;
		const valueIUnknown = noResultAvailable && !valueContainsSelected && !selectedContainsValue;
		const shouldShowAlert = value.trim() !== '' && didTrySearchYet && isNotLoading && valueIUnknown;

		return (
			<>
				<input {...props} className={`form-control ${styles.formControl}`} placeholder="Barrio, Comuna o ciudad" />
				{shouldShowAlert &&
					<SearchAlert status='error'>
						<label>Tu búsqueda no tiene resultados.</label>
						<p>Te recomendamos buscarlo nuevamente.</p>
					</SearchAlert>
				}
			</>
		);
	};

	const notFoundElement = () => <SearchAlert status='error'>
		<div>Tu búsqueda no tiene resultados.</div>
		<p>Te recomendamos buscarlo nuevamente.</p>
	</SearchAlert>;

	const handleAddressSearch = (e) => {
		if (selectedValue === null && searchResult.length == 0)
			return false;

		e.preventDefault();
		const valueToSend = selectedValue ?? [...searchResult].slice(0, 1).pop();

		if (valueToSend === null)
			return;
		const url = handlerBusquedaTexto(getSearchText(valueToSend), valueToSend.id, null, null);
		setLastSearchResult(valueToSend);

		window.location = url;
	};

	const handlerBusquedaTexto = (texto, idPoligono, viewport, esVistaLista) => {

		const basePath = `${urlBase}/resultados`;
		const viewType = `${esVistaLista != null ? (esVistaLista ? 'lista' : 'mapa') : (isMobile ? 'lista' : 'mapa')}`;
		const operationTypePath = getTypeOptionSlug(operationType, 'compra');
		const propertyTypePath = getTypeOptionSlug(propertyType, 'casa-departamento');
		const polygonParam = `idPoligono=${idPoligono != "" ? idPoligono : 0}`;
		const textParam = `texto=${texto != null ? texto : ''}`;
		const viewportParam = `viewport=${viewport != null ? viewport : ''}`;

		return `${basePath}/${viewType}/${operationTypePath}/${propertyTypePath}/?${polygonParam}&${textParam}&${viewportParam}`;
	};

	const getTypeOptionSlug = (type, defaultSlug = null) => {
		if (type === null || type === undefined)
			return defaultSlug;
		const { slug, tipo } = type;
		let tmpTipoPo = slug ?? tipo?.toLowerCase().replace(/ /g, '');

		return tmpTipoPo;
	};

	const handlerSearchByCode = (e) => {
		e.preventDefault();
		searchByCodeCallback(searchByCodeValue)
			.then(response => {
				if (!response.Exito)
					return setSearchByCodeStatus({ status: 'error', message: response.Mensaje });
				setSearchByCodeStatus({ status: 'success', message: "Cargando ficha", url: response.UrlReturn });
			})
			.catch(ex => {
				setSearchByCodeStatus({ status: 'error', message: ex.message });
			});
	};

	const handlerInputPropertyId = (e) => {
		setSearchByCodeValue(e.target.value);
		setSearchByCodeStatus({ status: 'idle', message: '' });
	};
	const handleSearchSubmit = (e) => {
		e.preventDefault();
		const { target } = e;
		const { dataset } = target ?? {};
		const { searchType } = dataset ?? {};

		if (+searchType === OpcionBusqueda.buscadorUbicacion)
			handleAddressSearch(e);
		if (+searchType === OpcionBusqueda.buscardorCodigo)
			handlerSearchByCode(e);

	};
	return (
		<div className={styles.contenedorBuscador}>
			<div className='container'>
				<div className={styles.contenedorTitulo}>
					<h2 className={styles.ttBuscador}>Hola{name && ` ${name}`}, <span className={styles.ttBuscador2}>busca aquí&nbsp; </span></h2>
					<span className={styles.ttBuscador2}> tu próximo hogar</span>
				</div>
				<form onSubmit={handleSearchSubmit} data-search-type={tipoBuscador}>
					{tipoBuscador === OpcionBusqueda.buscadorUbicacion ?
						<div className={styles.contenedorDesktop}>
							<div className={styles.contenedorOpciones}>
								<OpcionBuscador
									id={"selector-operation"}
									sessionId={sessionId}
									email={email}
									clase={styles.btnBorde}
									opcionesBuscador={tipoBusqueda.listaTipoOperaciones}
									setValueCallback={setOperationType}
									defaultIndex={defaultOperationIndex}
								/>
								<OpcionBuscador
									id={"selector-property"}
									sessionId={sessionId}
									email={email}
									opcionesBuscador={tipoBusqueda.listaTipoPropiedades}
									setValueCallback={setPropertyType}
								/>
							</div>
							<SearchInput
								items={searchResult}
								inputElement={inputElement}
								notFoundElement={notFoundElement}
								setSearchResult={setSearchResult}
								searchCallback={searchCallback}
								SearchResultRowComponent={SearchResultRow}
								AutocompleteComponent={Autocomplete}
								setSelectedValueCallback={setSelectedValue}
								searchValue={initSearchValue}
							>

							</SearchInput>

							<div id="hp-btn-buscar-normal" data-id-usuario={usuario ? idUser : 0} data-tipo-propiedad={propertyType?.tipo}
								data-tipo-operacion={operationType?.tipo} data-nombre-ubicacion={`${selectedValue?.label},${selectedValue?.sublabel}`}
								data-validacion={selectedValue ? 'correcto' : 'error'}
								data-email={email} data-event={"click"} data-event-key={'buscar-normal'}
								data-location={"home"} data-session-id={sessionId}
								data-event-value={`${selectedValue?.label},${selectedValue?.sublabel}`}
								className={styles.btnBuscar} onClick={handleAddressSearch}>Buscar</div>
						</div>
						:
						<div>
							<p className={styles.subttCodigo}>Busca propiedad por código</p>
							<div className={styles.contenedorDesktop}>
								<input type="text" className={`form-control ${styles.formControlCodigo}`}
									onChange={handlerInputPropertyId}
									value={searchByCodeValue}
									placeholder="Ingresa código" />
								<div id="hp-btn-buscar-codigo" data-id-usuario={usuario ? idUser : 0} data-id-proyecto={searchByCodeValue} data-validacion={searchByCodeValue ? 'correcto' : 'error'} className={styles.btnBuscar} onClick={handlerSearchByCode} >Buscar</div>
							</div>
						</div>
					}
				</form>
				{
					tipoBuscador === OpcionBusqueda.buscadorUbicacion ?
						<a className={styles.buscarCodigo} onClick={() => setTipoBuscador(OpcionBusqueda.buscardorCodigo)}>Buscar propiedad por código</a>
						:
						<a className={styles.buscarCodigo} onClick={() => setTipoBuscador(OpcionBusqueda.buscadorUbicacion)}>Buscar propiedad por comuna o región</a>
				}
				{searchByCodeStatus?.status === 'error' &&
					<SearchAlert>
						<label>Tu búsqueda no tiene resultados.</label>
						<p>Te recomendamos buscarlo nuevamente.</p>
					</SearchAlert>
				}
				<div className={styles.mobile}>
					<ImgBuscadorMobile />
				</div>
			</div>
		</div>
	);
};

export default Buscador;