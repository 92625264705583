import React, { useState } from 'react';
import styles from './PreFooterStatic.module.scss';

const PreFooterStatic = ({ dataPreFooter, isMobile, defaultOperacionIndex = 0 }) => {
	const [operacionIndex] = useState(defaultOperacionIndex);
	const [propiedadIndex] = useState(0);
	const [regionIndex] = useState(0);
	// Extraemos los datos del nuevo formato
	const tiposTransaccion = dataPreFooter.tipo_transaccion || [];
	const tiposPropiedad = dataPreFooter.tipo_propiedad || [];
	const regiones = dataPreFooter.regiones || [];

	return (
		<div className={`container ${styles.contenedorPrincipal}`}>
			{isMobile ? <h3>¡Encuentra tu propiedad ideal!</h3> : <h2>¡Encuentra tu propiedad ideal!</h2>}
			
			{/* Tipos de Transacción (Comprar/Arrendar) */}
			<div value={operacionIndex} aria-label="tipos de operación" className={`${isMobile ? styles.contenedorOperacion : ""}`}>
				{tiposTransaccion.map((operacion, index) => (
					<button 
						key={index}
						onClick={() => {
							window.location.href = operacion.url;
						}}
						className={`${styles.tabHeader} ${styles.primaryTabHeader} ${operacionIndex === +index ? styles.activeButton : ""}`}
					>
						{operacion.text}
					</button>
				))}
			</div>

			{/* Contenedor principal que muestra según la operación seleccionada */}
			<div className={styles.contenedorSecundario}>
				{/* Tipos de Propiedad */}
				<div value={propiedadIndex} aria-label="tipos de propiedad" className={`${styles.contenedorPropiedad}`}>
					{isMobile ? (
						<div className={styles.mobileSelect}>
							<p className={styles.nativeSelectLabel}>Tipo de propiedad</p>
							<select
								aria-label="tipos de propiedad"
								onChange={(e) => {
									window.location.href = `${tiposTransaccion[operacionIndex].url}${tiposPropiedad[e.target.value].url}`;
								}}
								className={`${styles.nativeSelect}`}
							>
								{tiposPropiedad.map((propiedad, index) => (
									<option key={index} value={index}>
										{propiedad.text}
									</option>
								))}
							</select>
							<div className={`${styles.chevron} ${styles.chevronArrowDown}`}></div>
						</div>
					) : (
						tiposPropiedad.map((propiedad, index) => (
							<button 
								key={index}
								onClick={() => {
									window.location.href = `${tiposTransaccion[operacionIndex].url}${propiedad.url}`;
								}}
								className={`${styles.tabHeader} ${styles.secondaryTabHeader} ${propiedadIndex === +index ? styles.activeButton : ""}`}
							>
								{propiedad.text}
							</button>
						))
					)}
				</div>

				{/* Selector de Regiones */}
				<div value={regionIndex} aria-label="regiones" className={`${styles.contenedorPropiedad}`}>
					{isMobile ? (
						<div className={styles.mobileSelect}>
							<p className={styles.nativeSelectLabel}>Región</p>
							<select
								onChange={(e) => {
									window.location.href = `${tiposTransaccion[operacionIndex].url}${tiposPropiedad[propiedadIndex].url}${regiones[e.target.value].url}`;
								}}
								className={`${styles.nativeSelect}`}
							>
								{regiones.map((region, index) => (
									<option key={index} value={index}>
										{region.text}
									</option>
								))}
							</select>
							<div className={`${styles.chevron} ${styles.chevronArrowDown}`}></div>
						</div>
					) : (
						regiones.map((region, index) => (
							<button 
								key={index}
								onClick={() => {
									window.location.href = `${tiposTransaccion[operacionIndex].url}${tiposPropiedad[propiedadIndex].url}${region.url}`;
								}}
								className={`${styles.tabHeader} ${styles.secondaryTabHeader} ${regionIndex === +index ? styles.activeButton : ""}`}
							>
								{region.text}
							</button>
						))
					)}
				</div>

				{/* Lista de Comunas de la región seleccionada */}
				<div className={`${styles.contenedorPropiedad}`}>
					{isMobile ? (
						<div className={styles.mobileSelect}>
							<p className={styles.nativeSelectLabel}>Comuna</p>
							<select
								className={`${styles.nativeSelect}`}
								onChange={(e) => {
									const comuna = regiones[regionIndex].comunas[e.target.value];
									window.location.href = `${tiposTransaccion[operacionIndex].url}${tiposPropiedad[propiedadIndex].url}${regiones[regionIndex].url}${comuna.url}`;
								}}
							>
								<option value="" disabled selected>Selecciona una comuna</option>
								{regiones[regionIndex]?.comunas.map((comuna, index) => (
									<option key={index} value={index}>
										{comuna.text}
									</option>
								))}
							</select>
							<div className={`${styles.chevron} ${styles.chevronArrowDown}`}></div>
						</div>
					) : (
						<div className={`${styles.preFooterLinkContainer}`}>
							{regiones[regionIndex] && (
								<div className={styles.preFooterLink}>
									<ul className={`${styles.contenedorUl} ${styles.columnasComuna}`}>
										{regiones[regionIndex].comunas.map((comuna, idx) => (
											<li key={idx} className={styles.noBulletPoints}>
												<a href={`${tiposTransaccion[operacionIndex].url}${tiposPropiedad[propiedadIndex].url}${regiones[regionIndex].url}${comuna.url}`}>
													{comuna.text}
												</a>
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default PreFooterStatic;